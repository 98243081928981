import React from 'react'
import { Typography, Grid, makeStyles, createStyles } from '@material-ui/core';
import image from './kswans-com-bg.jpg'

const useStyles = makeStyles(() => createStyles({
    divContainer: {
        backgroundImage: `url(${image})`,
        backgroundSize: 'cover',
        height: '100%',
        flexGrow: 1,
    },
    name: {
        marginTop: '35vh',
        marginLeft: '10em'
    }
}));

const Header: React.FC = () => {
    const classes = useStyles();

    return (
        <div className={classes.divContainer}>
            <Grid container>
                <Grid item xs={12}>
                    <Grid item className={classes.name}>
                        <Typography align="left" variant="h1">Hello, I'm</Typography>
                        <Typography align="left" variant="h2">Karl Swanson</Typography>
                        <Typography align="left" variant="body1">Student @ NEU | Engineer | Developer | Climber</Typography>
                    </Grid>
                </Grid>
            </Grid>
        </div>
    )
}

export default Header;