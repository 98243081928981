import React from 'react'
import { Grid, Typography, makeStyles, createStyles } from '@material-ui/core';
import KSwanImage from './images/kswan.jpg'

const useStyles = makeStyles(() => createStyles({
    gridContainer: {
        background: '#8496b4'
    },
    gridContent: {
        marginTop: '4em',
    },
    aboutContent: {
        marginTop: '5em',
        paddingBottom: '8em'
    },
    titleFont: {
        color: 'white',
    },
    paragraphFont: {
        color: 'white',
        float: 'left',
        textAlign: 'justify',
        paddingBottom: '2em'
    },
    image: {
        width: '300px',
        borderRadius: '300px',
        border: '4px solid white',
    }
}));

const About: React.SFC = () => {
    const classes = useStyles();

    return (
        <Grid container className={classes.gridContainer} justify="center">
            <Grid item container className={classes.gridContent} md={10} lg={8}>
                <Grid item xs={12}>
                    <Typography className={classes.titleFont} variant="h3">
                        About me
                    </Typography>
                </Grid>
                <Grid item className={classes.aboutContent} xs={6}>
                    <Typography className={classes.paragraphFont} variant="h4">
                        Karl Swanson
                    </Typography>
                    <Typography className={classes.paragraphFont} variant="body1">
                        Student at Northeastern University, currently pursuing a combined BS in Computer Engineering and Computer Science.
                        Interested in automation and it's role in removing humans from tedious and dangerous situations, how can we make someone's job easier, and give them more time back into their day to day life?
                    </Typography>
                    <Typography className={classes.paragraphFont} variant="body1">
                        President of AerospaceNU, responsible for managing a student body over over 150 active members attending project meeting every week.
                    </Typography>
                </Grid>
                <Grid item container alignItems="center" direction="column" justify="center" className={classes.aboutContent} xs={6}>
                    <Grid item>
                        <img className={classes.image} src={KSwanImage} alt="Racing Lightnings @ Squam Regatta" />
                    </Grid>
                </Grid>
            </Grid>
        </Grid>
    )
}

export default About;