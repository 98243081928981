import React from 'react';

import './App.css';
import Timeline from './Timeline/Timeline';
import Header from './Header/Header';
import About from './About/About';
import Projects from './Projects/Projects';

function App() {
  return (
    <div className="App">
      <Header />
      <About />
      <Timeline />
      <Projects />
    </div>
  );
}

export default App;
