import React from 'react'
import './styles/projects.css'
import { Grid, Card, Typography, CardActionArea, CardContent, makeStyles, createStyles, CardMedia } from '@material-ui/core';
import InfinityCosmeticsJpg from './images/infinity-cosmetics.jpg'
import SwarmAnimatedGif from './images/swarm-animated.gif'
import NasaRoverJpg from './images/nasa-rover.jpg'
import PynqVisualizerGif from './images/pynq-visualizer.gif'
import TargetAltitudeJpg from './images/target-altitude.jpg'
import MicroAltimeterJpg from './images/micro-altimeter.jpg'

const useStyles = makeStyles(theme => createStyles({
    root: {
        backgroundColor: '#f5f5f5',
    },
    titleText: {
        color: 'black',
        marginTop: '1em'
    },
    container: {
        flexWrap: 'wrap',
        [theme.breakpoints.up('lg')]: {
            width: '70vw',
            margin: '3em 15vw 3em 15vw',
        },
        [theme.breakpoints.down('lg')]: {
            margin: '3em 5vw 3em 5vw',
            width: '90vw'
        }
    },
    media: {
        height: '300px',
    },
}));

const Projects: React.SFC = () => {
    const classes = useStyles();

    return (
        <Grid container className={classes.root} justify="space-around">
            <Grid item xs={12}>
                <Typography className={classes.titleText} variant="h3">Projects</Typography>
            </Grid>
            <Grid container className={classes.container} justify="space-around" spacing={6}>
                <Grid item sm={12} md={6} lg={4}>
                    <Card>
                        <CardActionArea>
                            <CardMedia className={classes.media} image={InfinityCosmeticsJpg} title="Infinity Cosmetics Prototype" />
                            <CardContent>
                                <Typography gutterBottom variant="h5" component="h2">
                                    Infinity Cosmetics Startup
                            </Typography>
                                <Typography variant="body2" color="textSecondary" component="p">
                                    Developed custom nail polish mixing process and constructed prototype utilizing peristaltic pumps to dispense polish for mixing. The prototype was developed initially in Solidworks prior to construction. The prototype functions using
                                    a Wi-Fi enabled ESP32 programmed in C++ with PlatformIO. A web interface designed with ReactJS is used to place orders on the device.
                                </Typography>
                            </CardContent>
                        </CardActionArea>
                    </Card>
                </Grid>
                <Grid item sm={12} md={6} lg={4}>
                    <Card>
                        <CardActionArea>
                            <CardMedia className={classes.media} image={SwarmAnimatedGif} title="Swarm Carrier Presentation" />
                            <CardContent>
                                <Typography gutterBottom variant="h5" component="h2">
                                    NUAV Software Lead
                            </Typography>
                                <Typography variant="body2" color="textSecondary" component="p">
                                    Led a team of 10-20 undergraduate students in developing software for Northeastern University's drone development group.
                                    Working to develop software with ROS, React, C++ / Python stack enabling drone autonomy. We have currently tested several
                                    algorithms for locating a lost person (simulated by a radio beacon), and navigating to a given radio signal.
                            </Typography>
                            </CardContent>
                        </CardActionArea>
                    </Card>
                </Grid>
                <Grid item sm={12} md={6} lg={4}>
                    <Card>
                        <CardActionArea>
                            <CardMedia className={classes.media} image={NasaRoverJpg} title="NASA SL 2018 Rover" />
                            <CardContent>
                                <Typography gutterBottom variant="h5" component="h2">
                                    NASA SL Software Lead
                            </Typography>
                                <Typography variant="body2" color="textSecondary" component="p">
                                    Led a team of undergraduate students in developing the electrical and software systems for Northeastern University's NASA
                                    Student Launch team in 2018. Designed rover PCB and wrote software to enable deployable rover solar panel, live telemetry / control,
                                    and rover ejection from launch vehicle. Worked with XBEE radios to provide telemetry.
                            </Typography>
                            </CardContent>
                        </CardActionArea>
                    </Card>
                </Grid>
                <Grid item sm={12} md={6} lg={4}>
                    <Card>
                        <CardActionArea>
                            <CardMedia className={classes.media} image={PynqVisualizerGif} title="Aniamted PYNQ Music Visualizer" />
                            <CardContent>
                                <Typography gutterBottom variant="h5" component="h2">
                                    PYNQ FPGA Audio Visualizer
                            </Typography>
                                <Typography variant="body2" color="textSecondary" component="p">
                                    Worked with the PYNQ Z2 board to develop an Audio Visualizer using the Fast Fourier Transform IP provided by Vivado. Utilized the
                                    Vivado Design Studio and Vivado HLS to create IP Blocks that allow for FFT over AXI Direct Memory Axis blocks. Final project for
                                    Advanced Digital Logic Design class at Northeastern University.
                            </Typography>
                            </CardContent>
                        </CardActionArea>
                    </Card>
                </Grid>
                <Grid item sm={12} md={6} lg={4}>
                    <Card>
                        <CardActionArea>
                            <CardMedia className={classes.media} image={TargetAltitudeJpg} title="Target Altitude Ballistic" />
                            <CardContent>
                                <Typography gutterBottom variant="h5" component="h2">
                                    Altitude Controlled Ejection
                            </Typography>
                                <Typography variant="body2" color="textSecondary" component="p">
                                    Created a system for the Target Altitude BOTR 2017 competition, designed to deploy a drogue parachute after reaching a target
                                    altitude of 1500ft. The system proved funcitonal over 4 launches. The result of the final launch can be seen below, the software
                                    may have benefit from a couple more unit tests...
                                </Typography>
                            </CardContent>
                        </CardActionArea>
                    </Card>
                </Grid>
                <Grid item sm={12} md={6} lg={4}>
                    <Card>
                        <CardActionArea>
                            <CardMedia className={classes.media} image={MicroAltimeterJpg} title="Nano Altimeter" />
                            <CardContent>
                                <Typography gutterBottom variant="h5" component="h2">
                                    Nano Altitude Logger
                            </Typography>
                                <Typography variant="body2" color="textSecondary" component="p">
                                    Created an altitude logger for use with ESTES model rockets with an Arduino and BMP180 sensor. The system is powered off a single AAA
                                    battery and can run for weeks without losing power. Designed to fit in a .25" avionics section, this project was the topic of one of my
                                    IA assessments for IB in High School.
                                </Typography>
                            </CardContent>
                        </CardActionArea>
                    </Card>
                </Grid>
            </Grid >
        </Grid>
    );
}

export default Projects