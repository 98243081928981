import React from 'react'
import { VerticalTimeline, VerticalTimelineElement } from 'react-vertical-timeline-component';
import 'react-vertical-timeline-component/style.min.css';
import { Work } from '@material-ui/icons';
import { Typography, makeStyles, createStyles } from '@material-ui/core';

const useStyles = makeStyles(() => createStyles({
    titleFont: {
        paddingTop: '1em',
        paddingBottom: '1em',
        color: '#c7255f',
    },
    container: {
        paddingBottom: '3em',
        backgroundColor: '#e3e3e3'
    }
}));

export const Timeline: React.FC = () => {
    const classes = useStyles();

    return (
        <div className={classes.container}>
            <Typography className={classes.titleFont} variant="h4">
                Professional Experience
            </Typography>
            <VerticalTimeline>
                <VerticalTimelineElement
                    className="vertical-timeline-element--work"
                    contentStyle={{ background: 'white', color: 'black' }}
                    contentArrowStyle={{ borderRight: '7px solid white' }}
                    date="July 2019 - December 2019"
                    iconStyle={{ background: '#234b60', color: '#e79b3d' }}
                    icon={<Work />}>
                    <h3 className="vertical-timeline-element-title">Software Developer Co-op</h3>
                    <h4 className="vertical-timeline-element-subtitle">Square Robot, Boston MA</h4>
                    <p>
                        Developed software for Autonomous Underwater Vehicle working a ROS C++ / Python stack. Developed firmware for
                        robot charge control using CAN Bus and CANOpen Protocol with Mbed OS.
                    </p>
                </VerticalTimelineElement>
                <VerticalTimelineElement
                    className="vertical-timeline-element--work"
                    contentStyle={{ background: 'white', color: 'black' }}
                    contentArrowStyle={{ borderRight: '7px solid white' }}
                    date="July 2018 - December 2018"
                    iconStyle={{ background: '#61a20b', color: '#fff' }}
                    icon={<Work />}>
                    <h3 className="vertical-timeline-element-title">Systems Test Engineer Co-op</h3>
                    <h4 className="vertical-timeline-element-subtitle">iRobot, Bedford MA</h4>
                    <p>
                        Developed computer vision software capable of tracking in a 3D space.
                        Designed test fixture to support computer vision software, aided in the design and construction of other various fixtures.
                        Iterated on fixture designs in an agile workflow.
                    </p>
                </VerticalTimelineElement>
                <VerticalTimelineElement
                    className="vertical-timeline-element--work"
                    contentStyle={{ background: 'white', color: 'black' }}
                    contentArrowStyle={{ borderRight: '7px solid white' }}
                    date="May 2017 - August 2017"
                    iconStyle={{ background: '#0061b2', color: '#79b249' }}
                    icon={<Work />}>
                    <h3 className="vertical-timeline-element-title">Dashboard Tech Intern</h3>
                    <h4 className="vertical-timeline-element-subtitle">Stored Technology Solutions, Queensbury NY</h4>
                    <p>
                        Troubleshot monitoring agent and antivirus deployments on Kaseya and GFI dashboards.
                        Coordinated software deployment with various clients/organizations on workstations and laptops.
                        Configured LAN Cache for various organizations and performed dashboard migrations.
                    </p>
                </VerticalTimelineElement>
                <VerticalTimelineElement
                    className="vertical-timeline-element--work"
                    contentStyle={{ background: 'white', color: 'black' }}
                    contentArrowStyle={{ borderRight: '7px solid white' }}
                    date="July 2016 - August 2016"
                    iconStyle={{ background: '#f7d64b', color: '#255fa8' }}
                    icon={<Work />}>
                    <h3 className="vertical-timeline-element-title">Technology Intern</h3>
                    <h4 className="vertical-timeline-element-subtitle">Queensbury School District, Queensbury NY</h4>
                    <p>
                        Diagnosed network connectivity/uptime issues, gained experience with routers and switch boxes.
                        Performed system installations with imaging software and servers, deployed workstations across school district.
                        Resolved helpdesk tickets created by teachers and staff regarding broken printers, workstations, and display devices.
                    </p>
                </VerticalTimelineElement>
            </VerticalTimeline>
        </div>
    );
}

export default Timeline;